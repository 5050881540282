import { css } from 'styled-components'

import { breakpoints } from '../constants/breakpoints'

type MediaBreakpoint = Record<keyof typeof breakpoints, <T>(styles: T) => T>

/**
 * @deprecated Use `media` from `ui-primitives` instead
 */
export const media = Object.keys(breakpoints).reduce((acc, label) => {
  acc[label] = styles => css`
    @media (min-width: ${breakpoints[label]}px) {
      ${styles};
    }
  `

  return acc
}, {} as MediaBreakpoint)

/**
 * @deprecated Use `mediaMax` from `ui-primitives` instead
 */
export const mediaMax = Object.keys(breakpoints).reduce<MediaBreakpoint>(
  (acc, label) => {
    acc[label] = styles => css`
      @media (max-width: ${breakpoints[label] - 1}px) {
        ${styles};
      }
    `

    return acc
  },
  {} as MediaBreakpoint
)

/**
 * @deprecated Use `mediaObj` from `ui-primitives` instead
 */
export const mediaObj = Object.keys(breakpoints).reduce((acc, label) => {
  acc[label] = `@media (min-width: ${
    breakpoints[label as keyof typeof breakpoints]
  }px)`

  return acc
}, {} as typeof breakpoints)

/**
 * @deprecated Use `mediaMaxObj` from `ui-primitives` instead
 */
export const mediaMaxObj = Object.keys(breakpoints).reduce((acc, label) => {
  acc[label] = `@media (max-width: ${
    breakpoints[label as keyof typeof breakpoints] - 1
  }px)`

  return acc
}, {} as typeof breakpoints)
