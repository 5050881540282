import { useEffect, useRef } from 'react'
import ReactCalendar from 'react-calendar'
import { createPortal } from 'react-dom'
import { useTranslation } from 'react-i18next'
import { isSameMonth } from 'date-fns/isSameMonth'
import styled, { createGlobalStyle, css } from 'styled-components'

import { formatDateInUTC } from 'bl-utils/src/formatting/formatDate'

import { colors } from '../../constants/colors'
import { zIndex } from '../../constants/zIndex'
import { media } from '../../utils/media'

type ContainerProps = {
  isVisible?: boolean
}

const Container = styled.div<ContainerProps>`
  display: none;
  position: absolute;
  z-index: ${zIndex.above2};
  top: 0;
  max-width: 825px;
  width: 100%;
  ${({ isVisible }) =>
    isVisible &&
    css`
      display: block;
    `}

  ${media.md(css`
    top: auto;
  `)}
`

const CalendarStyles = createGlobalStyle`
.react-calendar {

  width: 100%;
  background: white;
  border: 1px solid #a0a096;
  line-height: 1.125em;
  padding: ${({ theme }: { theme: any }) => theme.spacing[2]};
}
.react-calendar--doubleView .react-calendar__viewContainer {
  display: flex;
  margin: -0.5em;
}
.react-calendar--doubleView .react-calendar__viewContainer > * {
  width: 50%;
  margin: .5em;
}
.react-calendar button {
  border: 0;
  margin: 0;
  outline: none;
}
.react-calendar button:hover {
  cursor: pointer;
}
.react-calendar__tile:disabled:hover {
  cursor: initial;
}

.react-calendar__navigation {
  height: 44px;
  margin-bottom: 1em;
}
.react-calendar__navigation__label {
  font-weight: 500;
  font-size: 24px;
  color: ${colors.deepBlue};
  pointer-events: none;
  ${media.md(css`
    font-size: 30px;
  `)}
}
.react-calendar__navigation__label:hover {
  cursor: default;
}
.react-calendar__navigation__prev2-button,
.react-calendar__navigation__next2-button
 {
   display: none;
}
.react-calendar__navigation button {
  min-width: 44px;
  background: none;
}
.react-calendar__navigation__arrow {
  background: #FFFFFF;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  font-size: 2em;
  color: ${colors.deepBlue};
}
.react-calendar__navigation button[disabled] {
  opacity: 0;
  display: none;
}
.react-calendar__month-view__weekdays {
  text-align: center;
  text-transform: uppercase;
  font-weight: 500;
  font-size: .75em;
}
.react-calendar__month-view__weekdays abbr {
  text-decoration: none;
}
.react-calendar__month-view__weekdays__weekday {
  padding: .5em;
}
.react-calendar__month-view__weekNumbers {
  font-weight: bold;
}
.react-calendar__month-view__weekNumbers .react-calendar__tile {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: .75em;
  padding: 1em 0.6666666666666666em;
}
.react-calendar__month-view__days__day {
  font-size: 20px;
  font-weight: 300;
  /* margin: 20px 0; */
  min-height: 64px;
}
.react-calendar__month-view__days__day abbr {
  display: block;
  position: relative;
  z-index: 1;
}

.react-calendar__tile.react-calendar__tile--active.react-calendar__month-view__days__day abbr {
  color: white;
}

.react-calendar__tile.react-calendar__tile--active.react-calendar__month-view__days__day abbr::after {
  background: ${colors.deepBlue};
  border-radius: 50%;
  bottom: 0;
  content: '';
  display: block;
  left: 0;
  margin: auto;
  position: absolute;
  right: 0;
  top: 0;
  z-index: -1;
  width: 34px;
  height: 34px;

  ${media.md(css`
    height: 64px;
    width: 64px;
  `)}
}

.react-calendar__month-view__days__day--weekend {
  /* color: #d10000; */
}
.react-calendar__month-view__days__day--neighboringMonth {
  /* color: #757575; */
}
.react-calendar__year-view .react-calendar__tile,
.react-calendar__decade-view .react-calendar__tile,
.react-calendar__century-view .react-calendar__tile {
  padding: 2em .5em;
}
.react-calendar__tile {
  max-width: 100%;
  text-align: center;
  background: none;
}
.react-calendar__tile:disabled abbr {
  color: #d8d8d8;
}

`

function usePortal(id) {
  const rootElemRef = useRef(document.createElement('div'))

  useEffect(function setupElement() {
    // Look for existing target dom element to append to
    const parentElem = document.querySelector(`${id}`)
    // Add the detached element to the parent
    parentElem.appendChild(rootElemRef.current)
    // This function is run on unmount
    return function removeElement() {
      rootElemRef.current.remove()
    }
  }, [])

  return rootElemRef.current
}

export const Calendar = ({
  onChange,
  onClose,
  defaultValue = undefined,
  isVisible,
  minDate,
  maxDate,
  portalEl = '[data-portal="calendar"]',
  isDateDisabled,
}) => {
  if (typeof window === 'undefined') {
    return null
  }

  const ref = useRef(null)
  const el = usePortal(portalEl)
  const {
    i18n: { language },
  } = useTranslation()

  const handleMouseDown = e => {
    if (isVisible && ref.current && !ref.current.contains(e.target)) {
      onClose()
    }
  }

  const tileDisabled = ({ date, view, activeStartDate }) => {
    if (view === 'month') {
      if (!isSameMonth(date, activeStartDate)) {
        return true
      } else if (isDateDisabled) {
        return isDateDisabled({ date, view, activeStartDate })
      }
    }
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleMouseDown)
    return () => {
      document.removeEventListener('mousedown', handleMouseDown)
    }
  }, [isVisible])

  const portal = (
    <Container isVisible={isVisible} ref={ref}>
      <CalendarStyles />

      <ReactCalendar
        defaultValue={defaultValue}
        minDate={minDate || Date.now()}
        maxDate={maxDate}
        view="month"
        onChange={onChange}
        formatMonthYear={(_, date) =>
          formatDateInUTC(date, 'MMMM yyyy', language)
        }
        formatShortWeekday={(_, date) => formatDateInUTC(date, 'E', language)}
        tileDisabled={tileDisabled}
      />
    </Container>
  )

  return createPortal(portal, el)
}
