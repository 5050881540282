import { FC, ReactNode } from 'react'
import { FocusOn } from 'react-focus-on'
import { useTranslation } from 'react-i18next'
import Portal from '@reach/portal'
import { AnimatePresence, motion } from 'framer-motion'
import { rgba } from 'polished'
import styled, { css } from 'styled-components'

import { colors } from '../constants/colors'
import { zIndex } from '../constants/zIndex'
import { CloseButton } from '../elements/CloseButton'
import { Type } from '../elements/Typography/Typography'
import {
  BookingEngine,
  DeepPartial,
  PartialBookingEngine,
} from '../styles/types'
import { media } from '../utils/media'

interface DrawerProps {
  show: boolean
  children: ReactNode
  target?: string
  delay?: number
  offset?: number
  shards?: Array<React.RefObject<any> | HTMLElement>
  withHeader?: boolean
  noPadding?: boolean
  closeButton?: ReactNode
  fullHeight?: boolean
  onBack?: () => void
  onDismiss: () => void
  themeStyle?: DeepPartial<BookingEngine>
}

interface WithOffset {
  offset: number
}

const DrawerRoot = styled(motion.div)`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: ${zIndex.newLayer};
`

const Backdrop = styled(motion.div)`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  z-index: ${zIndex.behind};
  background-color: rgba(0, 0, 0, 0.25);
  -webkit-backdrop-filter: blur(3px);
  backdrop-filter: blur(3px);
`

const DrawerWrapper = styled(motion.div)`
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  max-height: 100vh;
  max-height: 100dvh;
  z-index: ${zIndex.above};
`

const Content = styled.div<{ fullHeight: boolean }>`
  position: relative;
  width: 100%;
  height: ${({ fullHeight }) => (fullHeight ? '100dvh' : 'auto')};
  max-height: 100vh;
  max-height: 100dvh;
  background: white;
  box-shadow: 0px 10px 40px rgba(0, 0, 0, 0.15);
  overflow: hidden;
`

const ScrollContainer = styled.div<
  WithOffset & {
    headerHeight: number
    noPadding: boolean
    themeSyle?: PartialBookingEngine['drawer']['scrollContainer']
  }
>`
  position: relative;
  width: 100%;
  height: ${({ offset }) => `calc(100% - ${offset}px)`};
  max-height: inherit;
  overflow-y: scroll;
  background: ${props => props?.themeSyle?.background ?? colors.white};
  color: ${props => props?.themeSyle?.color ?? 'inherit'};

  ${({ headerHeight = 0, noPadding }) =>
    !noPadding &&
    css`
      padding: ${({ theme }) =>
        `calc(${theme.spacing[3.5]} + ${headerHeight}px) 0 ${theme.spacing[2.5]}`};
    `}

  ${({ headerHeight = 0, noPadding }) =>
    !noPadding &&
    media.sm(css`
      padding: ${({ theme }) =>
        `calc(${theme.spacing[3.5]} + ${headerHeight}px) 0 ${theme.spacing[2.5]}`};
    `)}

    ${({ headerHeight = 0, noPadding }) =>
    !noPadding &&
    media.mlg(css`
      padding: ${({ theme }) =>
        `calc(${theme.spacing[2.5]} + ${headerHeight}px) 0 ${theme.spacing[2]}`};
    `)};
`

const Header = styled.div<{
  themeStyle?: PartialBookingEngine['drawer']['header']
}>`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 60px;
  z-index: ${zIndex.above};
  background: ${props => props?.themeStyle?.background ?? colors.white};

  padding: ${({ theme }) => `${theme.spacing[1]} 0`};

  display: flex;
  justify-content: space-between;
  padding: ${({ theme }) => `${theme.spacing[1]} 0`};
  border-bottom: ${props =>
    `1px solid ${
      props?.themeStyle?.borderColor ?? rgba(colors.midGrey, 0.15)
    }`};
`

const BackButton = styled.button<{
  themeStyle?: PartialBookingEngine['drawer']['backButton']
}>`
  display: flex;
  padding: ${({ theme }) => `${theme.spacing[1]} ${theme.spacing[2]}`};
  color: ${props => props?.themeStyle?.color ?? colors.deepBlue};
  cursor: pointer;
  justify-content: center;
  align-items: center;

  svg {
    padding-top: ${props => props.themeStyle?.svg?.paddingTop ?? 0};
    fill: ${props => props.themeStyle?.color ?? colors.deepBlue};
  }
`
const CloseButtonWrapper = styled.div`
  position: absolute;
  top: ${({ theme }) => theme.spacing[3.5]};
  right: ${({ theme }) => theme.spacing[2]};

  ${media.bmd(css`
    top: ${({ theme }) => theme.spacing[2.5]};
    right: ${({ theme }) => theme.spacing[2.5]};
  `)}
`

const BackArrow = () => (
  <svg
    width="13"
    height="10"
    viewBox="0 0 13 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M12.2074 5.78947H3.00079L6.10369 8.89238L4.99607 10L0 5.00393L1.10762 3.89631L4.99607 0L6.10369 1.11548L3.00079 4.21838H12.2074V5.78947Z" />
  </svg>
)

export const Drawer: FC<DrawerProps> = ({
  show,
  children,
  onDismiss,
  offset,
  onBack,
  shards,
  withHeader = false,
  closeButton,
  noPadding = false,
  fullHeight = false,
  themeStyle,
}) => {
  const { t } = useTranslation('booking')

  const transitionIn = {
    type: 'spring',
    mass: 1,
    damping: 35,
    stiffness: 140,
  }

  const transitionOut = {
    type: 'spring',
    mass: 1,
    damping: 26,
    stiffness: 170,
  }
  const variants = {
    hidden: {
      y: '100%',
      opacity: 0,
    },
    visible: {
      y: '0',
      opacity: 1,
      transition: transitionIn,
    },
    exit: {
      y: '100%',
      opacity: 0,
      transition: transitionOut,
    },
  }

  return (
    <AnimatePresence initial={false} exitBeforeEnter={true}>
      {show && (
        <Portal>
          <DrawerRoot>
            <Backdrop
              onClick={onDismiss}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1, transition: transitionIn }}
              exit={{ opacity: 0, transition: transitionOut }}
            />
            <FocusOn
              key="drawer"
              autoFocus={true}
              returnFocus
              onClickOutside={onDismiss}
              onEscapeKey={onDismiss}
              shards={shards}
            >
              <DrawerWrapper
                variants={variants}
                initial="hidden"
                animate="visible"
                exit="exit"
              >
                <Content
                  tabIndex={0}
                  role="dialog"
                  fullHeight={fullHeight}
                  aria-modal={true}
                  aria-labelledby="drawerTitle"
                  aria-describedby="drawerContent"
                >
                  {withHeader && (
                    <Header themeStyle={themeStyle?.drawer?.header}>
                      {onBack && (
                        <BackButton
                          onClick={onBack}
                          themeStyle={themeStyle?.breadcrumbField?.backButton}
                        >
                          <BackArrow />
                          <Type preset="label" left={{ xs: 0.5 }}>
                            {t('back')}
                          </Type>
                        </BackButton>
                      )}
                      <div>
                        <CloseButton
                          onClose={onDismiss}
                          color={themeStyle?.drawer?.header?.closeButtonColor}
                        />
                      </div>
                    </Header>
                  )}
                  <ScrollContainer
                    offset={offset}
                    headerHeight={withHeader ? 60 : 0}
                    noPadding={noPadding}
                    themeSyle={themeStyle?.drawer?.scrollContainer}
                  >
                    {children}
                    {!withHeader && (
                      <CloseButtonWrapper>
                        {closeButton || <CloseButton onClose={onDismiss} />}
                      </CloseButtonWrapper>
                    )}
                  </ScrollContainer>
                </Content>
              </DrawerWrapper>
            </FocusOn>
          </DrawerRoot>
        </Portal>
      )}
    </AnimatePresence>
  )
}
