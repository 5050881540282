import { motion } from 'framer-motion'
import styled, { css } from 'styled-components'
import { pb, pt } from 'styled-components-spacing'

import { colors } from '../../constants/colors'
import { Type } from '../../elements/Typography/Typography'
import { StyledInput } from '../../form/Input/Input'
import { media, mediaMax } from '../../utils/media'
import { mixins } from '../../utils/mixins'

type FieldWrapperProps = {
  isHidden?: boolean
  marginBottom?: number | string
  compact?: boolean
  hasError?: boolean
}

type NestedFieldProps = {
  top?: any
  bottom?: any
}

type FormProps = {
  withTopPadding?: boolean
}

type InlineProps = {
  top?: any
  bottom?: any
}

type CaptchaWrapperProps = {
  hide?: boolean
}

type RowItemProps = {
  width?: any
  spaceBetween?: any
}

export const Label = styled(({ ...props }) => (
  <Type
    as="label"
    size={{ xs: 14, md: 14 }}
    color={colors.dark}
    {...props}
    weight="bold"
  />
))`
  display: block;
  margin-bottom: 6px;
  flex: 0 0 auto;
`

export const FieldWrapper = styled.div<FieldWrapperProps>`
  margin-bottom: ${({ marginBottom }) =>
    typeof marginBottom === 'number' ? marginBottom : '24px'};
  ${({ isHidden = false }) =>
    isHidden &&
    css`
      display: none;
    `}
  ${({ compact = false }) =>
    compact &&
    css`
      margin-bottom: ${({ theme }) => theme.spacing[1]};
    `}

    ${StyledInput} {
    border-width: ${props => props?.hasError && '2px'};
  }

  .form-control {
    border-width: ${props => props?.hasError && '2px !important'};
  }
`

export const NestedField = styled.div<NestedFieldProps>`
  ${({ top, theme }) => pt(top, theme)};
  ${({ bottom, theme }) => pb(bottom, theme)};

  ${mediaMax.md(css`
    > * + * {
      margin-top: 15px;
    }
  `)}

  ${media.md(css`
    display: flex;
    > * {
      flex: 1 1 50%;
      & + * {
        margin-left: ${({ theme }) => theme.spacing[2]};
      }
    }
  `)}
`

export const NestedFieldInner = styled.div`
  display: flex;
  flex-direction: column;
`

export const CalendarWrapper = styled.div`
  position: relative;

  + ${NestedField} {
    margin: ${({ theme }) => theme.spacing[2]} 0;
  }
  > ${NestedField} {
    padding-bottom: 0;
  }
`

export const Form = styled.form<FormProps>`
  ${({ theme, withTopPadding }) =>
    !withTopPadding &&
    css`
      margin-top: ${theme.spacing[-2]};
    `};
`

export const SubmitButton = styled.div`
  width: 100%;

  ${media.md(css`
    width: ${mixins.span({ columns: 3, gutters: 2 })};
    margin-left: auto;
  `)};
`

export const TopWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`

export const ErrorMessage = styled(({ color, ...props }) => (
  <Type size={{ xs: 14, md: 14 }} weight="medium" {...props} />
))`
  color: ${props => props?.color ?? colors.errorRed};
  text-align: right;
  flex: 1;
`

export const Inline = styled.div<InlineProps>`
  align-items: center;
  display: flex;
  min-height: 2rem;

  ${({ top, theme }) => pt(top, theme)};
  ${({ bottom, theme }) => pb(bottom, theme)};
`

export const FormBottom = styled.div`
  display: flex;
  flex-direction: column;
  ${media.md(css`
    padding-top: 16px;
    flex-wrap: wrap;
    flex-direction: row;
  `)};
`

export const CaptchaWrapper = styled.div<CaptchaWrapperProps>`
  ${({ hide }) =>
    hide &&
    css`
      .grecaptcha-badge {
        visibility: hidden;
      }
      height: 0;
      width: 0;
    `}
  margin-bottom: 32px;

  ${media.md(css`
    margin: 0;
    margin-right: 16px;
  `)};
`
export const CaptchaTextWrapper = styled.div`
  margin-top: ${({ theme }) => theme.spacing[-2]};
  padding-bottom: ${({ theme }) => theme.spacing[2]};

  ${media.mlg(css`
    margin-top: ${({ theme }) => theme.spacing[-1.5]};
  `)};
  ${media.xl(css`
    margin-top: ${({ theme }) => theme.spacing[-1]};
  `)};
`
export const RadioWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  column-gap: ${({ theme }) => theme.spacing[2]};
  row-gap: ${({ theme }) => theme.spacing[2]};

  ${media.md(css`
    grid-template-columns: 1fr 1fr;
  `)};
`

export const TickWrapper = styled.svg`
  height: ${({ theme }) => theme.spacing[2]};
  width: ${({ theme }) => theme.spacing[2]};

  ${media.bmd(css`
    height: ${({ theme }) => theme.spacing[1.5]};
    width: ${({ theme }) => theme.spacing[1.5]};
  `)}
`

export const CheckWrapper = styled.svg`
  height: 18px;
  width: 18px;
  margin-right: 14px;
`

export const CouponSuccess = styled(motion.div)`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border: 1px solid #169222;
  background: #eaf4e9;
  padding: ${({ theme }) => `${theme.spacing[0.5]} ${theme.spacing[1]}`};
  display: flex;
  align-items: center;

  button {
    margin-left: auto;
  }
`

export const MultiFieldWrapper = styled.div`
  ${mediaMax.md(css`
    > * + * {
      margin-top: 15px;
    }
  `)}
  ${media.md(css`
    display: flex;
    > * + * {
      margin-left: ${({ theme }) => theme.spacing[2]};
    }
  `)}
`

export const NationalityWrapper = styled.div`
  width: 100%;

  select {
    height: 56px;
  }
`

export const Divider = styled.div`
  border-top: 1px solid #bfbfbf;
  margin-bottom: ${({ theme }) => theme.spacing[2]};
`

export const RowOuter = styled.div`
  /* margin-bottom: ${({ theme }) => theme.spacing[2]}; */
`

export const Row = styled.div`
  display: flex;
`

export const RowItem = styled.div<RowItemProps>`
  display: flex;
  flex-direction: column;

  ${({ width }) => {
    if (!width) {
      return undefined
    }
    return css`
      width: ${width};
    `
  }}

  ${({ spaceBetween }) => {
    if (!spaceBetween) {
      return undefined
    }
    return css`
      margin-right: ${spaceBetween};
    `
  }}
`

export const RowErrorMessage = styled(({ ...props }) => (
  <Type size={{ xs: 14, md: 14 }} weight="medium" {...props} />
))`
  margin-top: ${({ theme }) => theme.spacing[0.5]};
  color: ${colors.errorRed};
  text-align: left;
  flex: 1;
`
