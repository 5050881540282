import omit from 'lodash/omit'
import { darken, lighten, rgba } from 'polished'
import styled, { css } from 'styled-components'
import { map } from 'styled-components-breakpoint'
import { mb, ml, mr, mt } from 'styled-components-spacing'

import { colors } from '../../constants/colors'
import { modularScale } from '../../constants/sizes'
import { between } from '../../utils/between'
import { media, mediaMax } from '../../utils/media'
import { Type } from '../Typography/Typography'

export const buttonPadding = {
  small: () => css`
    padding: ${between(16 / modularScale, 16)} ${between(20 / modularScale, 20)};
    ${mediaMax.md(css`
      padding: 13px 20px 14px;
    `)};
  `,
  medium: () => css`
    padding: ${between(18 / modularScale, 18)} ${between(24 / modularScale, 24)};
    ${mediaMax.md(css`
      padding: 13px 24px 14px;
    `)};
  `,
  large: () => css`
    padding: ${between(18 / modularScale, 18)} ${between(54 / modularScale, 54)};
    ${mediaMax.md(css`
      padding: 13px 54px 14px;
    `)};
  `,
  menu: () => css`
    padding: ${between(18 / modularScale, 18)} ${between(40 / modularScale, 40)};
    ${mediaMax.md(css`
      padding: 13px 40px 14px;
    `)};
  `,
}

export const buttonPresets = {
  blue: {
    from: colors.lagoonBlue,
    to: colors.lagoonGradientStep,
    hover: colors.deepBlue,
    degrees: '90deg',
    color: colors.white,
    progressPreset: {
      from: colors.lagoonBlue,
      to: colors.lagoonGradientStep,
      degrees: '90deg',
      color: colors.white,
    },
  },
  grey: {
    from: rgba(colors.gray, 0.95),
    to: rgba(colors.gray, 0.95),
    hover: lighten(0.05, colors.gray),
    degrees: '90deg',
  },
  red: {
    from: rgba('#9D3628', 0.95),
    to: rgba('#78434C', 0.95),
    hover: lighten(0.05, '#78434C'),
    degrees: '90deg',
    color: colors.white,
  },
  lightGrey: {
    from: rgba('#F2F2F2', 0.95),
    to: rgba('#F2F2F2', 0.95),
    hover: lighten(0.05, '#F2F2F2'),
    degrees: '90deg',
    color: 'inherit',
  },
  white: {
    from: rgba(colors.white, 0.95),
    to: rgba(colors.white, 0.95),
    hover: lighten(0.05, colors.white),
    degrees: '90deg',
    color: 'inherit',
  },
  whiteWithBlue: {
    from: rgba(colors.white, 0.95),
    to: rgba(colors.white, 0.95),
    hover: lighten(0.05, colors.white),
    degrees: '90deg',
    color: colors.deepBlue,
  },
  dark: {
    from: rgba('#50555B', 0.95),
    to: rgba('#50555B', 0.97),
    hover: 'rgba(100, 100, 100, 0.95)',
    degrees: '-125deg',
  },
  darker: {
    from: rgba('#3c4044', 0.95),
    to: rgba('#383b40', 0.95),
    hover: 'rgba(100, 100, 100, 0.6)',
    degrees: '-125deg',
  },
  black: {
    from: '#7C8083',
    to: '#282A2C',
    hover: 'rgba(100, 100, 100, 0.95)',
    color: colors.white,
    degrees: '136deg',
  },
  blackWithBorder: {
    from: 'transparent',
    to: 'transparent',
    hover: 'transparent',
    color: colors.midGrey,
    boxShadow: `inset 0 0 0 2px ${colors.midGrey}`,
  },
  blueLagoonLight: {
    from: colors.lagoonBlue,
    to: '#5B9DB4',
    hover: lighten(0.05, '#5B9DB4'),
    degrees: '-125deg',
  },
  blueLagoonDark: {
    from: darken(0.15, colors.lagoonBlue),
    to: darken(0.15, '#5B9DB4'),
    hover: darken(0.05, colors.lagoonBlue),
    degrees: '-125deg',
  },
  retreatSpaLight: {
    from: '#9C747F',
    to: '#8F727C',
    hover: lighten(0.1, '#9C747F'),
    degrees: '-125deg',
  },
  retreatSpaDark: {
    from: darken(0.2, '#9C747F'),
    to: darken(0.2, '#8F727C'),
    hover: darken(0.1, '#8F727C'),
    degrees: '-125deg',
  },
  silicaHotelLight: {
    from: '#A2999C',
    to: '#A0A4B3',
    hover: lighten(0.01, '#A0A4B3'),
    degrees: '-125deg',
  },
  silicaHotelDark: {
    from: darken(0.25, '#A2999C'),
    to: darken(0.25, '#A0A4B3'),
    hover: darken(0.05, '#A2999C'),
    degrees: '-125deg',
  },
  retreatHotelLight: {
    from: '#6F737E',
    to: '#6F747F',
    hover: lighten(0.1, '#6F747F'),
    degrees: '-125deg',
  },
  retreatHotelDark: {
    from: darken(0.2, '#6F737E'),
    to: darken(0.2, '#6F747F'),
    hover: darken(0.05, '#6F737E'),
    degrees: '-125deg',
  },
  transparent: {
    from: 'transparent',
    to: 'transparent',
    hover: 'transparent',
    degrees: '-125deg',
  },
  transparentWithBlueBorder: {
    from: 'transparent',
    to: 'transparent',
    hover: 'transparent',
    degrees: '90deg',
    color: colors.deepBlue,
    boxShadow: 'inset 0 0 0 2px #3A7E8D',
  },
  transparentWithWhiteBorder: {
    from: 'transparent',
    to: 'transparent',
    hover: 'transparent',
    degrees: '90deg',
    color: colors.lightGrey,
    boxShadow: 'inset 0 0 0 2px #F2F2F2',
  },
  transparentWithBlueBorderOnDark: {
    from: 'transparent',
    to: 'transparent',
    hover: 'transparent',
    degrees: '90deg',
    color: '#91D6E5',
    boxShadow: 'inset 0 0 0 2px #91D6E5',
  },
  highlandWhite: {
    from: rgba(colors.white, 0.95),
    to: rgba(colors.white, 0.95),
    hover: lighten(0.05, colors.white),
    degrees: '90deg',
    color: colors.highlandGreen,
    borderRadius: 10,
  },
  highlandGreen: {
    from: colors.highlandGreen,
    to: colors.highlandGreen,
    hover: darken(0.05, colors.highlandGreen),
    degrees: '90deg',
    color: colors.white,
    borderRadius: 10,
    progressPreset: {
      from: colors.highlandGreen,
      to: colors.highlandGreen,
      degrees: '90deg',
      color: colors.white,
    },
  },
  highlandSecondary: {
    from: rgba('#a19d82', 0.3),
    to: rgba('#a19d82', 0.3),
    hover: darken(0.1, rgba('#a19d82', 0.3)),
    degrees: '90deg',
    color: '#645f51',
    borderRadius: 10,
  },
  highlandWhiteBorder: {
    from: 'transparent',
    to: 'transparent',
    hover: 'transparent',
    degrees: '90deg',
    color: colors.white,
    boxShadow: 'inset 0 0 0 2px #F2F2F2',
    borderRadius: 10,
  },
  highlandGreenBorder: {
    from: 'transparent',
    to: 'transparent',
    hover: 'transparent',
    degrees: '90deg',
    color: '#645f51',
    boxShadow: `inset 0 0 0 2px ${'#645f51'}`,
    borderRadius: 10,
  },
}

interface ButtonPresetBase {
  from: string
  to?: string
  color?: string
  degrees?: string
}

const defaultDisabled: ButtonPresetBase = {
  from: '#f2f2f2',
  degrees: '90deg',
  color: '#454647',
}

const getBasicCSSByPreset = (preset: ButtonPresetBase) => css`
  background: linear-gradient(
    ${preset.degrees ?? '90deg'},
    ${preset.to ?? preset.from},
    ${preset.from}
  );

  ${preset.color && `color: ${preset.color || 'inherit'}`};
`

export const BaseButton = styled(({ innerComponent: Link, ...props }) => {
  const cleanProps = omit(props, [
    'bottom',
    'left',
    'right',
    'top',
    'borderRadius',
    'minWidth',
    'maxWidth',
    'paddingSize',
    'transparent',
    'buttonBorderRadius',
    'useProgress',
  ])
  return cleanProps.to ? (
    <Link role="button" {...cleanProps} />
  ) : (
    <button {...cleanProps} />
  )
})`
  ${({ minWidth = 'initial' }) =>
    map(minWidth, val => `min-width: ${between(val / modularScale, val)};`)};
  ${({ maxWidth = 'initial' }) =>
    map(maxWidth, val => `max-width: ${between(val / modularScale, val)};`)};
  ${({ paddingSize }) =>
    buttonPadding[paddingSize] && buttonPadding[paddingSize]()};
  ${({ width }) =>
    width &&
    css`
      width: ${width};
    `};
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  border: 0;
  border-radius: ${props => props.borderRadius || 0};
  ${({ preset }) =>
    preset &&
    buttonPresets[preset] &&
    css`
      ${getBasicCSSByPreset(buttonPresets[preset])}
      box-shadow: ${buttonPresets[preset].boxShadow || 'none'};
      border-radius: ${buttonPresets[preset].borderRadius || '0'}px;
    `};

  ${({ transparent }) => transparent && 'background transparent'};
  overflow: hidden;
  margin: 0;
  position: relative;

  cursor: pointer;

  ${({ top, theme }) => mt(top, theme)};
  ${({ bottom, theme }) => mb(bottom, theme)};
  ${({ left, theme }) => ml(left, theme)};
  ${({ right, theme }) => mr(right, theme)};

  :disabled {
    ${({ preset, useProgress }) =>
      getBasicCSSByPreset(
        useProgress && buttonPresets[preset]?.progressPreset
          ? buttonPresets[preset].progressPreset ?? defaultDisabled
          : buttonPresets[preset].disabledPreset ?? defaultDisabled
      )}

    cursor: default;
  }
`

export const HoverButton = styled(BaseButton)`
  & > * {
    position: relative;
    pointer-events: none;
  }

  &::before {
    --size: 400px;

    content: '';
    position: absolute;
    left: calc(-1 * var(--size) / 2);
    top: calc(-1 * var(--size) / 2);
    width: var(--size);
    height: var(--size);
    transition: opacity 0.5s linear;
    transform: translate(var(--x), var(--y));
    opacity: 0;
    pointer-events: none;

    ${({ preset }) =>
      preset &&
      buttonPresets[preset] &&
      css`
        background: radial-gradient(
          circle closest-side,
          ${buttonPresets[preset].hover},
          transparent
        );
      `};
  }

  ${media.md(css`
    &:hover::before {
      opacity: 1;
      will-change: left, top;
    }
  `)};

  :disabled {
    ${({ preset, useProgress }) =>
      getBasicCSSByPreset(
        useProgress && buttonPresets[preset]?.progressPreset
          ? buttonPresets[preset].progressPreset ?? defaultDisabled
          : buttonPresets[preset].disabledPreset ?? defaultDisabled
      )}

    cursor: default;

    &::before {
      --size: 100%;
      left: 0;
      top: 0;

      background: transparent;
    }
  }
`

export const Text = styled(Type)`
  transition: none;
  margin-bottom: 2px;
  margin-top: 1px;
`
