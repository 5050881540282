import { ChangeEventHandler, FC, HTMLProps, ReactNode } from 'react'
import styled from 'styled-components'

import { boxShadows } from '../../constants/boxShadows'
import { colors } from '../../constants/colors'
import { theme } from '../../styles/theme'

interface CheckboxProps {
  id: HTMLProps<HTMLInputElement>['id']
  name: HTMLProps<HTMLInputElement>['name']
  children?: ReactNode
  checked?: HTMLProps<HTMLInputElement>['checked']
  onChange?: ChangeEventHandler<HTMLInputElement>
  error?: boolean
  fillWidth?: boolean
  noAlignItems?: boolean
  labelVerticallyCentered?: boolean
  color?: string
  required?: boolean
}

const CheckBoxWrapper = styled.div<{
  fillWidth: boolean
  noAlignItems: boolean
}>`
  align-items: ${props => (props.noAlignItems ? 'unset' : 'center')};
  display: flex;
  position: relative;
  width: ${props => (props.fillWidth ? '100%' : 'unset')};
`

const Box = styled.div<{ error?: boolean; labelVerticallyCentered?: boolean }>`
  position: relative;
  margin-right: ${theme.spacing[1]};
  height: ${props => (props.labelVerticallyCentered ? '24px' : 'unset')};

  &::before {
    background: ${props =>
      props.error ? ' rgba(218, 75, 75, 0.15)' : 'transparent'};
    border: 2px solid
      ${props => (props.error ? colors.errorRed : colors.formGrey)};
    content: '';
    height: 24px;
    width: 24px;
    display: inline-block;
    transition: box-shadow 150ms, border 150ms;
  }

  &::after {
    border-bottom: 2px solid white;
    content: none;
    border-left: 2px solid white;
    display: block;
    height: 7px;
    left: 7px;
    position: absolute;
    top: 7px;
    transform: rotate(-45deg);
    width: 10px;
  }
`

const Input = styled.input<{ color: string }>`
  height: 0;
  width: 0;
  opacity: 0;
  position: absolute;

  &:checked + label ${Box}::after {
    content: '';
  }

  &:hover + label ${Box}::before {
    border-color: ${props => props.color ?? colors.fountainBlue};
    box-shadow: ${props => props.color ?? boxShadows.inputFocus};
  }

  &:checked + label ${Box}::before {
    background: ${props => props.color ?? colors.fountainBlue};
    border-color: ${props => props.color ?? colors.fountainBlue};
  }

  &:focus + label ${Box}::before {
    border-color: ${props => props.color ?? colors.fountainBlue};
    box-shadow: ${props => props.color ?? boxShadows.inputFocus};
  }
`

const Label = styled.label<{ centerVertically: boolean }>`
  position: relative;
  display: flex;
  align-items: ${props => (props.centerVertically ? 'center' : 'flex-start')};
  cursor: pointer;
  height: ${props => (props.centerVertically ? '24px' : 'unset')};
  text-align: left;
`

export const Checkbox: FC<CheckboxProps> = ({
  id,
  name,
  checked,
  onChange,
  error,
  children,
  fillWidth = true,
  noAlignItems = false,
  labelVerticallyCentered = false,
  color = colors.fountainBlue,
  required,
}) => {
  return (
    <CheckBoxWrapper fillWidth={fillWidth} noAlignItems={noAlignItems}>
      <Input
        id={id}
        name={name}
        checked={checked}
        type="checkbox"
        aria-invalid={error}
        onChange={onChange}
        color={color}
        required={required}
      />
      <Label htmlFor={id} centerVertically={labelVerticallyCentered}>
        <Box error={error} labelVerticallyCentered={labelVerticallyCentered} />
        <div>{children}</div>
      </Label>
    </CheckBoxWrapper>
  )
}
