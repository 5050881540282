import { useEffect, useState } from 'react'
import { useRouter } from 'next/router'
import { AnimatePresence } from 'framer-motion'
import styled, { css } from 'styled-components'

import { zIndex } from 'bl-common/src/constants/zIndex'
import { Notification } from 'bl-common/src/elements/Notification'
import { media } from 'bl-common/src/utils/media'

const NotificationContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: fixed;
  z-index: ${zIndex.aboveMenu};
  bottom: 4em;
  left: 3em;
  right: 3em;

  ${media.md(
    css`
      bottom: ${({ theme }) => theme.spacing[2]};
      left: ${({ theme }) => theme.spacing[3]};
      bottom: 3em;
      max-width: 70%;
      right: unset;
    `
  )}

  ${media.xl(
    css`
      max-width: calc(100% - 600px);
    `
  )}
`

export const Notifications = ({ notifications }) => {
  if (!notifications) {
    return null
  }

  const { asPath } = useRouter()
  const [notificationItems, setNotificationItems] = useState([])
  const [hiddenIds, setHiddenIds] = useState([])

  useEffect(() => {
    const notificationsToShow = notifications.filter(notification => {
      if (!notification.fields.pageUrls) {
        return false
      }

      return (
        notification.fields.pageUrls.includes(asPath) &&
        !hiddenIds.includes(notification.sys.id)
      )
    })

    setNotificationItems(notificationsToShow)
  }, [asPath, hiddenIds])

  const onCloseNotification = id => {
    const newHiddenIds = [...hiddenIds, id]
    setHiddenIds(newHiddenIds)
  }

  return (
    <NotificationContainer>
      <AnimatePresence initial={false}>
        {notificationItems.map(notification => (
          <Notification
            key={notification.sys.id}
            id={notification.sys.id}
            {...notification.fields}
            onClose={onCloseNotification}
          />
        ))}
      </AnimatePresence>
    </NotificationContainer>
  )
}
