/* eslint-disable react/display-name */

import { RouterContext } from './RouterContext'

export const withRouter = Component => outerProps =>
  (
    <RouterContext.Consumer>
      {routerProps => <Component {...outerProps} {...routerProps} />}
    </RouterContext.Consumer>
  )
